import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import tippy from "tippy.js";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function pluralize(text, count) {
  if (count == 1) return `${count} ${text}`;

  const numeral = count === null ? "" : `${count} `;
  if (text == "person") return `${numeral}people`;

  if (text == "status") return `${numeral}statuses`;

  if (text == "match") return `${numeral}matches`;

  // Check if the word ends in "y", and if so, replace "y" with "ies"
  if (text.endsWith("y")) {
    // Make sure not to replace if the letter before y is a vowel, as in "keys"
    const vowels = ["a", "e", "i", "o", "u"];
    if (!vowels.includes(text.charAt(text.length - 2))) {
      return `${numeral}${text.substring(0, text.length - 1)}ies`;
    }
  }

  return `${numeral}${text}s`;
}

export function getQueryParam(key) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
}

export function getAllQueryParams(key) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.getAll(key);
}

export function setQueryParam(key, value) {
  // Update url query param without reloading the page
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState({}, "", url);
}

export function appendQueryParam(key, value) {
  // Update url query param without reloading the page
  const url = new URL(window.location);
  url.searchParams.append(key, value);
  window.history.pushState({}, "", url);
}

export function deleteQueryParam(key) {
  // Update url query param without reloading the page
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.pushState({}, "", url);
}

export function pick(obj, arr) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k]) => arr.includes(k)),
  );
}

export function indexBy(array, attr) {
  return array.reduce((accumulator, currentValue) => {
    const key = currentValue[attr];
    accumulator[key] = currentValue;
    return accumulator;
  }, {});
}

export function tooltipIfTruncated(e, fullText) {
  const el = e.target;
  el._tippy?.destroy(); // Delete previous instance

  if (el && el.offsetWidth < el.scrollWidth) {
    tippy(el, {
      content: fullText,
      delay: [250, 0],
      onShow: (instance) => {
        tippy.hideAll({ exclude: instance });
      },
    });
  }
}

export function downloadFile(url) {
  // Create an anchor element
  const link = document.createElement("a");

  // Set the URL for the download
  link.href = url;

  // Append the anchor to the body (required for Firefox)
  document.body.appendChild(link);

  // Programmatically click the anchor
  link.click();

  // Remove the anchor from the document
  document.body.removeChild(link);
}

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toInitials(name) {
  const parsed_name = name.split(" ");

  if (parsed_name.length > 1) {
    return (
      parsed_name[0][0] + parsed_name[parsed_name.length - 1][0]
    ).toUpperCase();
  }

  return parsed_name[0].slice(0, 2).toUpperCase();
}
